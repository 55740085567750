let stopSignPoll = false;
let signOrderRef = '';
let cgiPoll = null;

jQuery(function ($) {
    $('#apply_extra_card').validate({
        rules: {
            extra_personal_number: {
                required: true,
                isValidSwedishSSN: true,
                number: true
            },
            extra_first_name: "required",
            extra_last_name: "required",
            extra_email: {
                required: true,
                email: true
            },
            extra_phone_number: {
                required: true,
                validPhoneNumber: "SE",
            }
        },
        messages: {
            extra_personal_number: {
                required: "Ange extrakortsökandens personnummer 12 siffror",
                isValidSwedishSSN: "Kontrollera personnumret (12 siffror)",
                number: "Ange endast siffror",
            },
            extra_first_name: "Ange extrakortsökandens förnamn",
            extra_last_name: "Ange extrakortsökandens efternamn",
            extra_email: {
                required: "Ange extrakortsökandens e-postadress",
                email: "Ange en giltig e-postadress"
            },
            extra_phone_number: {
                required: "Ange extrakortsökandens telefonnummer",
                validPhoneNumber: "Ange ett giltigt telefonnummer"
            }
        },
        submitHandler: function (form, e) {
            sendExtraCardApplication(form, e);
        }
    });

    function sendExtraCardApplication(form, event) {
        event.preventDefault();
        $('.application-loader').removeClass('hidden');

        var form_data = $(form).serializeArray().reduce(function (obj, item) {
            obj[item.name] = item.value;
            return obj;
        }, {});

        stopSignPoll = false;

        var data = {
            action: 'cgi_apply_extra',
            security: CGIAjax.security,
            ssn: form_data.cgi_ssn,
            id: form_data.cgi_card_id,
            offer_id: form_data.cgi_offer_id,
        };

        $('.application-loader').removeClass('hidden');
        $('#apply_extra_card .cgi-message').html('').hide();

        $.post(CGIAjax.ajaxurl, data, function (res) {
            try {
                var response = jQuery.parseJSON(res);
            }
            catch {
                $('#apply_extra_card .cgi-message').html('Ett oväntat fel har uppstått. Vänligen försök igen eller kontakta kundtjänst om felet återkommer.').show();
                $('.application-loader').addClass('hidden');
                $('.application-loader-header').html('Signering startar...');
                $('.application-loader-instructions').html('<a class="cgi-abort-sign" href="#">Avbryt</a>');
                return;
            }

            if (response.status == 'ok') {
                signOrderRef = response.data.transactionId;

                if (response.isMobile == true) {
                    $('.application-loader-header').html(response.instruction);
                    $('.application-loader-instructions').html('Klicka här för att<br /><a class="et_pb_button" href="bankid:///?' + response.data.autostartToken + '&redirect=null">Starta BankID-appen</a><br /><a class="cgi-abort-sign" href="#">Avbryt</a>');
                    if (response.isiOS == true) {
                        location.href = 'bankid:///?' + response.data.autostartToken + '&redirect=' + encodeURIComponent(location.href);
                    } else {
                        location.href = 'bankid:///?' + response.data.autostartToken + '&redirect=null';
                    }
                } else {
                    $('.application-loader-header').html('Starta BankID-appen.');
                    $('.application-loader-instructions').html('<a class="cgi-abort-sign" href="#">Avbryt</a>');

                    if (response.data.qrData) {
                        var qrcode = new QRCode({
                            content: response.data.qrData,
                            width: 160,
                            height: 160,
                            join: true //Crisp rendering and 4-5x reduced file size
                        });
                        document.getElementById("qr-code").innerHTML = qrcode.svg();
                    }
                }

                pollSign(signOrderRef);
            } else {
                try {
                    if (typeof response.error[0].message !== 'undefined') {
                        $('#apply_extra_card .cgi-message').html(response.error[0].message).show();
                    }
                } catch {
                    $('#apply_extra_card .cgi-message').html('Ett oväntat fel har uppstått. Vänligen försök igen eller kontakta kundtjänst om felet återkommer.').show();
                }
                $('.application-loader').addClass('hidden');
                $('.application-loader-header').html('Signering startar...');
                $('.application-loader-instructions').html('<a class="cgi-abort-sign" href="#">Avbryt</a>');
            }
        });
    }

    function pollSign(signOrderRef) {
        if (stopSignPoll) {
          return;
        }
        var data = {
          action: 'cgi_card_extra_collect',
          order_ref: signOrderRef,
          security: CGIAjax.security,
        };

        $.ajax({
          url: CGIAjax.ajaxurl,
          data: data,
          error: function () {
            if (!stopSignPoll) {
                cgiPoll = setTimeout(function() {
                  pollSign(signOrderRef)
                }, 1000);
            }
          },
          success: function (res) {
            try {
              var response = jQuery.parseJSON(res);
            }
            catch {
              $('#apply_extra_card .cgi-message').html('Ett oväntat fel har uppstått. Vänligen försök igen eller kontakta kundtjänst om felet återkommer.').show();
              $('.application-loader').addClass('hidden');
              $('.application-loader-header').html('Signering startar...');
              $('.application-loader-instructions').html('<a class="cgi-abort-sign" href="#">Avbryt</a>');
              stopSignPoll = true;
              return;
            }

            if (response.status == 'complete') {
              $('.extra-card-content').html('<div class="cgi-filled-primary thank-you"><h3>Tack för din beställning</h3><p>Ditt kort kommer om ca. 1 vecka. Extrakortet kommer att synas inom några dagar här på Mina Sidor.</p><a href="." class="et_pb_button cgi-submit-input">Beställ fler extrakort</a></div>');
            } else if (response.status == 'error') {
              $('#apply_extra_card .cgi-message').html(response.error[0].message).show();

              $('.application-loader').addClass('hidden');
              $('.application-loader-header').html('Signering startar...');
              $('.application-loader-instructions').html('<a class="cgi-abort-sign" href="#">Avbryt</a>');
            } else if (response.status == 'userSign') {
              $('.application-loader-header').html('Nu kan du legitimera dig med BankID.');
              $('.application-loader-instructions').html(response.instruction);

              if (!stopSignPoll) {
                cgiPoll = setTimeout(function() {
                  pollSign(signOrderRef)
                }, 1000);
              }
            } else if (response.status == 'outstandingTransaction' || response.status == 'noClient') {
              if (response.isMobile == true) {
                $('.application-loader-header').html(response.instruction);
                $('.application-loader-instructions').html('Klicka här för att<br /><a class="et_pb_button" href="bankid:///?' + response.autostartToken + '&redirect=null">Starta BankID-appen</a><br /><a class="cgi-abort-sign" href="#">Avbryt</a>');
              } else {
                if ( response.qrData ) {
                  var qrcode = new QRCode({
                    content: response.qrData,
                    width: 160,
                    height: 160,
                    join: true //Crisp rendering and 4-5x reduced file size
                  });
                  document.getElementById("qr-code").innerHTML = qrcode.svg();
                }
                $('.application-loader-header').html(response.instruction);
                $('.application-loader-instructions').html('<a class="cgi-abort-sign" href="#">Avbryt</a>');
              }

              if (!stopSignPoll) {
                cgiPoll = setTimeout(function() {
                  pollSign(signOrderRef)
                }, 1000);
              }
            } else {
              if (!stopSignPoll) {
                cgiPoll = setTimeout(function() {
                  pollSign(signOrderRef)
                }, 1000);
              }
            }
          }
        });
      }

      // Abort sign process
      $('.application-loader').on('click', '.cgi-abort-sign', function (e) {
        e.preventDefault();

        stopSignPoll = true;
        clearInterval(cgiPoll);

        $('.application-loader-header').html('Avbryter signering...');
        $('.application-loader-instructions').html('');

        var data = {
          action: 'cgi_abort_extra_collect',
          order_ref: signOrderRef,
          security: CGIAjax.security
        };

        $.post(CGIAjax.ajaxurl, data, function () {
          $('.application-loader').addClass('hidden');
          $('.application-loader-header').html('Signering startar...');
          $('.application-loader-instructions').html('<a class="cgi-abort-sign" href="#">Avbryt</a>');
        });
      });

});
